 // TODO: Insert the other quarters 
  
  export const consumerDebtIndex = [
    { year: '2019-10', percentage: 49 },
    { year: '2020-10', percentage: 37 },
    { year: '2021-07', percentage: 42 }, // Where is 2021-10?
    { year: '2022-10', percentage: 47 },
    { year: '2023-10', percentage: 56 },
    { year: '2024-10', percentage: 44 },
  ];

  // { year: '2024-07', number: 48 },
  // Can check "Table 2" from Raw Ipsos polls for regional data and add "200 or less" plus "already insolvent"
  // May need to search a bit.


  // 2024-10: https://mnpdebt.ca/en/resources/mnp-debt-blog/rising-cost-living-albertans-make-sacrifices
  // 