  // Note that the Canada Income Survey is collected in the year AFTER the reference year
  // E.g., the 2022 survey data is collected in January-June 2023
  // https://www150.statcan.gc.ca/n1/daily-quotidien/240426/dq240426a-eng.htm

  // Confirm month ranges for other survey years

  export const povertyData = [
    { year: '2019-06', food_insecurity: 19.2, poverty: 8.8 },
    { year: '2020-06', food_insecurity: 18.5, poverty: 7.7 },
    { year: '2021-06', food_insecurity: 19.7, poverty: 5.5 },
    { year: '2022-06', food_insecurity: 21.8, poverty: 7.8 },
    { year: '2023-06', food_insecurity: 27.4, poverty: 9.7 },
  ];
